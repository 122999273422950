import styled, { css, keyframes } from 'styled-components'

const pulse = keyframes`
  0% { transition: none; transform: translate(-50%,-50%) scale(.5); }
  100% { transition: none; transform: translate(-50%,-50%) scale(.6); }
`;

export const Cursor = styled.span`
  position: fixed;
  bottom: -5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 1px solid navy;
  opacity: .4;
  user-select: none;
  pointer-events: none;
  will-change: top, left;
  transition-property: transform, border; 
  transition-duration: .4s;

  ${props => props.isActive && css`
    border-color: ${props.theme.color.primary};
    transform: translate(-50%,-50%) scale(.5);
    opacity: 1;
    border-width: 2px;
    animation: ${pulse} .6s .4s alternate infinite ease-in-out;
  `}

  @media(hover: hover) and (pointer: fine) {
    display: block;
  }
`;

export function setCursor ( setActiveCursor ) {
  
  let follower = document.getElementById('cursor');
  
  window.onmousemove = event => {
    follower.style.top = event.clientY + 'px';
    follower.style.left = event.clientX + 'px';
  }

  const interactiveElements = document.getElementsByTagName('a');
  console.log('interactiveElements', interactiveElements);
  
  if ( interactiveElements && interactiveElements.length > 0) {
    console.log('interactiveElements yep', interactiveElements);

    for (let i=0 ; i<interactiveElements.length ; i++){

      interactiveElements[i].addEventListener("mouseover", function() {   
        setActiveCursor(true);
      }, false);

      interactiveElements[i].addEventListener("mouseleave", function() {   
        setActiveCursor(false);
      }, false);

    }

  }  
}