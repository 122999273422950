import styled, { keyframes } from 'styled-components'

import { rgba } from 'polished'

const sectionAppear = keyframes`
  0% { opacity: 0; transform: translateY(1rem); }
  100% { opacity: 1; transform: translateY(0); }
`;

const volet = keyframes`
  0% { transform: scaleX(0); transform-origin: left; }
  40% { transform: scaleX(1); transform-origin: left; }
  40.1% { transform: scaleX(1); transform-origin: right; }
  100% { transform: scaleX(0); transform-origin: right; }
`;

const appear = keyframes`
  0% { opacity: 1; }
  100% { opacity: 1; }
`;

export const SectionLogos = styled.section`
  position: relative;
  background-color: ${rgba('#A6A8D4', .1)};
  animation: ${sectionAppear} .4s .2s forwards cubic-bezier(0.610, -0.515, 0.650, 1.325);
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #A6A8D4;
    transform: scaleX(0);
    animation: ${volet} .6s .4s forwards cubic-bezier(0.610, -0.515, 0.650, 1.325);
    z-index: 1;
  }
`;

export const LogoGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 6rem;
  opacity: 0;
  animation: ${appear} .1s .6s forwards;

  @media (max-width: 768px) {
    padding: 20px 20px 40px 20px;
  }

  li {
    flex: 1 0 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    .logoNurun { transform: scale(.7); }
    .logoHavas { transform: scale(.5); }
    .logoCirque { transform: scale(1.2); }
  }

  svg {
    color: navy;
    opacity: .35;
    width: 100px;
    height: 100px;
  }
`;