/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from 'react'

import GlobalStyle from '../styles/global'
import PropTypes from 'prop-types'
import {ThemeProvider} from 'styled-components'
import theme from '../styles/theme'

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle theme={theme}/>
      <ThemeProvider theme={theme}>
        <Fragment>
          {children}
        </Fragment>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
