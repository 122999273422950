/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"

import Helmet from "react-helmet"
import PropTypes from "prop-types"
import React from "react"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      script={[{
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context':'http://schema.org',
          '@type':'Organization',
          name:'Baptiste Macaire',
          legalName:'Baptiste Macaire',
          url:'https://baptistemacaire.com/',
          logo:'https://baptistemacaire.com/og-image.png',
          foundingDate:'2017',
          founders:[{
            '@type':'Person',
            name:'Baptiste Macaire'
          }],
          address:{
            '@type':'PostalAddress',
            streetAddress:'Chabot Street',
            addressLocality:'Montreal',
            addressRegion:'QC',
            postalCode:'H2H',
            addressCountry:'CANADA'
          },
          contactPoint:{
            '@type':'ContactPoint',
            contactType:'sales',
            telephone:'[+514-568-5123]',
            email:'baptiste.mac@gmail.com',
            availableLanguage:['French', 'English']
          }
        })
      }]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
