import styled, { keyframes } from 'styled-components'

// import { rgba } from 'polished'

const appear = keyframes`
  from { opacity: 0; transform: translateY(2rem); line-height: 3.5rem; }
  to { opacity: 1; transform: translateY(0); line-height: default; }
`;

export const SectionHero = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 300px;
  margin: 3rem auto;
  
  @media (max-width: 768px) {
    margin: 3rem auto;
  }

  a {
    font-size: 1.3rem;
    padding: .3rem 0;
  }
`;

export const Paragraph = styled.p`
  /* position: absolute; */
  /* top: 50%; */
  /* left: 15vw; */
  max-width: 70vw;
  font-size: 1.5rem;
  line-height: 2.8rem;
  /* transform: translateY(-50%); */
  will-change: opacity, transform, line-height;
  animation: ${appear} .6s forwards cubic-bezier(0.610, -0.515, 0.650, 1.325);
  
  @media (max-width: 768px) {
    /* position: relative; */
    /* top: auto; */
    /* left: auto; */
    max-width: 80vw;
    /* padding: 0 3rem; */
    font-size: 1.3rem;
    line-height: 2.2rem;
    /* transform: none; */
    /* animation: none; */
  }
`;

export const Emphase = styled.em`
  font-style: normal;
  text-transform: capitalize;
  color: ${props => props.theme.color.primary};
`;

export const Divider = styled.br`
  @media (max-width: 768px) {
    display: none;
  }
`;