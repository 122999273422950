import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  
  *, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    color: ${props => props.theme.color.secondary};
    font-family: ${props => props.theme.font.primary};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
    cursor: url("cursor/cursor-pointer.svg") 2 2, crosshair; /* FF */
    cursor: -webkit-image-set(url("cursor/cursor-pointer.png") 1x, url("cursor/cursor-pointer@2x.png") 2x) 2 2, crosshair; /* Webkit */
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${props => props.theme.color.primary};
  }

  ul {
    list-style: none;
  }
`
export default GlobalStyle