import * as s from './style'

import Logo4U2C from '../../assets/logos/logo-4U2C.svg'
import LogoAirCanada from '../../assets/logos/logo-air-canada.svg'
import LogoBelairdirect from '../../assets/logos/logo-belairdirect.svg'
import LogoCanal from '../../assets/logos/logo-canal.svg'
import LogoCirqueDuSoleil from '../../assets/logos/logo-cirque-du-soleil.svg'
import LogoDesjardins from '../../assets/logos/logo-desjardins.svg'
import LogoDevMcGill from '../../assets/logos/logo-devmcgill.svg'
import LogoHavas from '../../assets/logos/logo-havas.svg'
import LogoLoreal from '../../assets/logos/logo-loreal.svg'
import LogoMontrealInternational from '../../assets/logos/logo-montreal-international.svg'
import LogoNurun from '../../assets/logos/logo-nurun.svg'
import LogoSTM from '../../assets/logos/logo-stm.svg'
import LogoTV5Monde from '../../assets/logos/logo-TV5monde.svg'
import LogoValtech from '../../assets/logos/logo-valtech.svg'
import LogoWeightWatchers from '../../assets/logos/logo-weightwatchers.svg'
import React from 'react'

const SectionLogos = () => {
  return (
    <s.SectionLogos>
      <s.LogoGrid>
        
        <li><LogoNurun className='logoNurun' /></li>
        <li><LogoHavas className='logoHavas' /></li>
        <li><LogoValtech /></li>
        <li><LogoCanal /></li>
        <li><LogoCirqueDuSoleil className='logoCirque' /></li>

        <li><LogoTV5Monde /></li>
        <li><LogoMontrealInternational /></li>
        <li><Logo4U2C /></li>
        <li><LogoSTM /></li>
        <li><LogoLoreal /></li>

        <li><LogoAirCanada /></li>
        <li><LogoDevMcGill /></li>
        <li><LogoWeightWatchers /></li>
        <li><LogoBelairdirect /></li>
        <li><LogoDesjardins /></li>
        
      </s.LogoGrid>
    </s.SectionLogos>
  )
}

export default SectionLogos
