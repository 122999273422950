import * as s from './style';

import React from 'react'

const SectionHero = () => {
  return (
    <s.SectionHero>
      <h1 hidden>Product Designer Freelance</h1>
      <s.Paragraph>
        <s.Emphase>Hi.&nbsp;</s.Emphase>
        I am Baptiste Macaire, freelance Montreal-based designer.
        <br/>
        I <s.Emphase>design digital product</s.Emphase> and develop components (<s.Emphase>❤</s.Emphase> CSS-in-JS).&nbsp;
        {/* <s.Divider /> */}
        Feel free to ask me about <s.Emphase>design system</s.Emphase>, animation or design process. <a href="mailto:baptiste.mac@gmail.com">baptiste.mac@gmail.com</a>
        {/* &nbsp;<a class="link link--contact" target="_blank" rel="noopener noreferrer" href="http://ca.linkedin.com/in/baptistemacaire">Linkedin</a> */}
        {/* &nbsp;<a class="link" target="_blank" rel="noopener noreferrer" href="https://twitter.com/baptistemacaire">Twitter</a> */}
      </s.Paragraph>
    </s.SectionHero>
  )
}

export default SectionHero
