const theme = {
  
  color: {
    primary: '#e62300',
    secondary: 'navy',
  },

  font: {
    primary: 'Domine',
  }

};

export default theme