import { Cursor, setCursor } from '../styles/cursor'
import React, { useLayoutEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionHero from '../components/SectionHero'
import SectionLogos from '../components/SectionLogos'
import styled from 'styled-components'

const Page = styled.main`
  display: grid;
  grid-template-rows: auto 50%;
  min-height: 100vh;

  @media (max-width: 768px) {
    display: block;
  }
`;

function IndexPage(props) {
  console.log('IndexPage', props);

  const [isActiveCursor, setActiveCursor] = useState( false );

  useLayoutEffect(() => {
    setCursor(setActiveCursor);
  }, []);

  return (
  <Layout>
    <SEO title="Product Designer - Baptiste Macaire" />
    
    <Page>
      <SectionHero />
      <SectionLogos />
    </Page>
    
    <Cursor id='cursor' isActive={isActiveCursor}/>

  </Layout>
  
)}

export default IndexPage
